import React, { useState } from "react";
import { Card, CardBody, Container, Row, Col } from "reactstrap";
import DemoNavbar from "components/Navbars/DemoNavbar.js";
import NewFooter from "components/Footers/NewFooter.js";
import Helmet from "react-helmet";

class Recruitment extends React.Component {
  state = {};
  constructor(props) {
    super(props);
    this.state = {
      isEnglish: true,
      readMore: false,
    };
  }

  handleReadmore = () => {
    this.setState((prevState) => ({
      readMore: !prevState.readMore,
    }));
  };

  componentDidMount() {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    this.refs.main.scrollTop = 0;
  }
  render() {
    return (
      <>
        <Helmet>
          <title>
            Recruitment - Indian Institute of Information Technology Kota
          </title>

       
          <meta
            name="description"
            content="IIIT Kota Faculty Recruitment Page"
          />
          <meta
            name="keywords"
            content="IIIT Kota Recruitment, Faculty Recruitment IIIT Kota, CSE Faculty Recruitment IIIT Kota, ECE Faculty Recruitment IIIT Kota, IIIT Kota Faculty"
          />
        </Helmet>
        <DemoNavbar />
        <main ref="main">
          <div className="position-relative">
            {/* shape Hero */}
            <section className="section  section-shaped pb-200">
              <div className="shape shape-style-1 shape-default bg-gradient-default">
                <span />
                <span />
                <span />
                <span />
                <span />
                <span />
                <span />
                <span />
                <span />
              </div>

              <div className="separator separator-bottom separator-skew">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  preserveAspectRatio="none"
                  version="1.1"
                  viewBox="0 0 2560 100"
                  x="0"
                  y="0"
                >
                  <polygon className="fill-white" points="0 0 0 0 0" />
                </svg>
              </div>
            </section>
          </div>
          <section className="section section-lg">
            
            <Container>
              <Row className="justify-content-center text-center mb-5 ">
                <Col lg="8">
                  <h2>
                    <b>Recruitment</b>
                  </h2>
                  For any queries, please contact at{" "}
                  <a href="mailto:recruitment@iiitkota.ac.in">
                    {" "}
                    recruitment@iiitkota.ac.in{" "}
                  </a>
                </Col>
              </Row>

              {/* new starts here */}


              <Card className="card-lift--hover shadow border-0 mb-5">
                <CardBody className="py-4 text-justify">
                  <Row className="justify-content mt-2" id="foc10jan2022">
                    <Col lg={12} xs={12} className="text-justify">
                      <h6>
                        <b>
                        Applicants are invited to attend the Walk-in-Interview for the post of Medical Officer (on contract).
                        </b>
                      </h6>
                      <ul>
                        <li>
                        IIIT Kota invites Applications for the post of Medical Officer purely on short term
                         contract basis for an initial period of one year which can be further extended for one year 
                         based on the performance of the incumbent.
                        </li>
                        <li>
                          <a href="https://files.iiitkota.ac.in/pdf/recruitment/AddMedicalOfficer.pdf" target="_blank">
                            Advertisement
                          </a>
                       
                        </li>

                        <li>
                        <a href="https://recruitment.iiitkota.in/MO/index" target="_blank">
                            Apply Online
                          </a>
                        </li>

                        <li>
                        Date of Walk-in Interview: To be announced (TBA)
                        </li>

                        <li>
                        <b>Closing Date:</b> 24<sup>th</sup>{" "}Oct{" "}2024
                        </li>

                        
                        
                        
                      </ul>
                    </Col>
                  </Row>
                </CardBody>
              </Card>



              <Card className="card-lift--hover shadow border-0 mb-5">
                <CardBody className="py-4 text-justify">
                  <Row className="justify-content mt-2" id="foc10jan2022">
                    <Col lg={12} xs={12} className="text-justify">
                      <h6>
                        <b>
                        Advertisement for the post of Training and Placement Manager (Purely on short term contract basis)
                        </b>
                      </h6>
                      <ul>
                        <li>
                        IIIT Kota invites Applications for the post of Training and Placement Manager
                        purely on short term contract basis for an initial period of one year which can
                        be further extended upto three years based on the performance of the incumbent.
                        This post is purely on short term contract basis and the applicant will not have
                        any claim to regularization with IIIT Kota.
                        </li>
                        <li>
                          <a href="https://files.iiitkota.ac.in/pdf/recruitment/ADVERTIZEMENT TNP MANAGER IIIT KOTA .pdf" target="_blank">
                            Advertisement
                          </a>
                       
                        </li>

                        <li>
                        <a href="https://recruitment.iiitkota.in/TPO/" target="_blank">
                            Apply Online
                          </a>
                        </li>

                        <li>
                          <a href="https://files.iiitkota.ac.in/pdf/recruitment/Application Form TPM (Annexure 1).pdf" target="_blank">
                            Annexure-1
                          </a>
                       
                        </li>

                        <li>
                        <b>Closing Date:</b> 20<sup>th</sup>{" "}Oct{" "}2024
                        </li>
                        
                        
                      </ul>
                    </Col>
                  </Row>
                </CardBody>
              </Card>



              <div style={{ display: "flex", justifyContent: "center"}} className="mb-5">
                      <div
                        onClick={this.handleReadmore}
                        style={{
                          display: "flex",
                          alignItems: "center",
                          borderRadius: 4,
                          border: "solid 1px black",
                          padding: "3px 7px",
                          cursor: "pointer",
                          backgroundColor: "#0E407C",
                          color: "#ffffff",
                        }}
                      >
                       
                       {this.state.readMore?
                        
                        "Close Archive"
                        :"View Archive"
                       }
                      
                        
                      </div>
                    </div>

             
                    {this.state.readMore ?
                    
                    <>



              <Card className="card-lift--hover shadow border-0 mb-5">
                <CardBody className="py-4 text-justify">
                  <Row className="justify-content mt-2" id="foc10jan2022">
                    <Col lg={12} xs={12} className="text-justify">
                      <h6>
                        <b>
                        Advertisement for the Post of Project Staff (JRF) (Purely on Contract)
                        </b>
                      </h6>
                      <ul>
                        <li>
                        Applications are invited from Indian nationals for the post of Junior Research Fellow (JRF) on
                        contract in a research project entitled “Design and Development of IoMT based Anthropomorphic Robotic
                        Glove for Stress Detection and Aiding Hand Disabled People” sponsored by Central Research
                        Laboratory, Bharat Electronics Limited (BEL).
                        </li>
                        <li>
                          <a href="https://files.iiitkota.ac.in/pdf/announcement/Extension BEL Copy of Advertisement with application.pdf" target="_blank">
                            Advertisement
                          </a>
                       
                        </li>
                        
                        
                      </ul>
                    </Col>
                  </Row>
                </CardBody>
              </Card>



              <Card className="card-lift--hover shadow border-0 mb-5">
                <CardBody className="py-4 text-justify">
                  <Row className="justify-content mt-2" id="foc10jan2022">
                    <Col lg={12} xs={12} className="text-justify">
                      <h6>
                        <b>
                        Walk-In Interview for Guest Faculty
                        </b>
                      </h6>
                      <ul>
                        <li>
                        Indian Institute of Information Technology (IIIT) Kota invites Indian nationals for a Walk-In Interview for
                        the position of Guest Faculty for a period of 11 months on July 15-16, 2024. It is desirable that aspiring
                        candidates may submit their resume by email on recruitment@iiitkota.ac.in.
                        </li>
                        <li>
                          <a href="https://files.iiitkota.ac.in/pdf/recruitment/IIIT_Kota_Guest_Faculty_Advertisement_2024.pdf" target="_blank">
                            Advertisement
                          </a>
                       
                        </li>
                        
                        
                      </ul>
                    </Col>
                  </Row>
                </CardBody>
              </Card>


              <Card className="card-lift--hover shadow border-0 mb-5">
                <CardBody className="py-4 text-justify">
                  <Row className="justify-content mt-2" id="foc10jan2022">
                    <Col lg={12} xs={12} className="text-justify">
                      <h6>
                        <b>
                        Walk-in-interview for Teaching Associates
                        </b>
                      </h6>
                      <ul>
                        <li>
                        Indian Institute of Information Technology (IIIT) Kota invites Indian nationals for a Walk-In Interview for
                        the position of Teaching Associates for a period of 11 months on July 15-16, 2024. It is desirable that aspiring
                        candidates may submit their resume by email on recruitment@iiitkota.ac.in.
                        </li>
                        <li>
                          <a href="https://files.iiitkota.ac.in/pdf/recruitment/IIIT_Kota_Teaching_Associate_Advertisement_2024.pdf" target="_blank">
                            Advertisement
                          </a>
                        </li>
                        
                        
                      </ul>
                    </Col>
                  </Row>
                </CardBody>
              </Card>


             
              <Card className="card-lift--hover shadow border-0 mb-5">
                <CardBody className="py-4 text-justify">
                  <Row className="justify-content mt-2" id="foc10jan2022">
                    <Col lg={12} xs={12} className="text-justify">
                      <h6>
                        <b>
                        Advertisement for the post of Project Staff (JRF)
                        </b>
                      </h6>
                      <ul>
                        <li>
                        Applications are invited from Indian nationals for the post of Junior Research Fellow (JRF) on contract
                        in a research project entitled “Design and Development of Miniaturized Ultra-Wideband antenna
                        system for UAV mounted Ground Penetrating Radar System to detect Landmines and Explosives”
                        </li>
                        <li>
                          <a href="https://files.iiitkota.ac.in/pdf/recruitment/JRF_Advertisment_TiHAN.pdf" target="_blank">
                            Advertisement
                          </a>
                        </li>
                        <li>
                          <a href="https://forms.gle/M7LVZpa5t5QVzPio7" target="_blank">
                            Application Form
                          </a>
                        </li>
                        <li>
                          <b>Closing Date:</b><del>18th April, 2024</del>
                          <span style={{ color: "red", marginLeft: "4px" }}>
                            {" "}
                            Closed
                          </span>
                        </li>
                        
                      </ul>
                    </Col>
                  </Row>
                </CardBody>
              </Card>

              <Card className="card-lift--hover shadow border-0 mb-5">
                <CardBody className="py-4 text-justify">
                  <Row className="justify-content mt-2" id="foc10jan2022">
                    <Col lg={12} xs={12} className="text-justify">
                      <h6>
                        <b>
                        Advertisement for the Post of Faculty on Contract at IIIT Kota
                        </b>
                      </h6>
                      <ul>
                        <li>
                        Online applications are invited from Indian nationals for engagement of Faculty on contract for a period of 11 
                        months at Indian Institute of Information Technology (IIIT) Kota. Aspiring candidates may submit their 
                        applications in online mode latest by March 28, 2024 on the given link.
                        </li>
                        <li>
                          <a href="https://files.iiitkota.ac.in/pdf/recruitment/Contract Faculty Advt 2024.pdf" target="_blank">
                            Advertisement 
                          </a>
                        </li>
                        <li>
                          <a href="https://recruitment.mnit.ac.in/IIITK/2401/" target="_blank">
                            Apply Online
                          </a>
                        </li>
                          <li>
                          <b>Closing Date:</b><del>28th March, 2024</del>
                          <span style={{ color: "red", marginLeft: "4px" }}>
                            {" "}
                            Closed
                          </span>
                        </li>
                      </ul>
                    </Col>
                  </Row>
                </CardBody>
              </Card>

              <Card className="card-lift--hover shadow border-0 mb-5">
                <CardBody className="py-4 text-justify">
                  <Row className="justify-content mt-2" id="foc10jan2022">
                    <Col lg={12} xs={12} className="text-justify">
                      <h6>
                        <b>
                        Walk-in-interview for Teaching Associate
                        </b>
                      </h6>
                      <ul>
                        <li>
                        Walk-in-interview for the post of Teaching Associates for a period of 11 months in the discipline of 
                        Computer Science and Engineering, Department of Electronics and Communication Engineering,
                        Humanities and Management shall be held on 4
                        th April, 2024. Interested applicants are required to bring 
                        their resumes, relevant original documents with Xerox copy in support of their age, qualifications, 
                        experience, passport size photographs etc. at Indian Institute of Information Technology Kota, SPL269, RIICO Industrial Area, Kuber Extension, Ranpur, Kota (Rajasthan)–325003.
                        </li>
                        <li>
                          <a href="https://files.iiitkota.ac.in/pdf/recruitment/Advertisment Teaching Associate 2024.pdf" target="_blank">
                            Advertisement 
                          </a>
                        </li>
                          <li>
                          <b>Interview Date:</b><del>4th April, 2024</del>
                          <span style={{ color: "red", marginLeft: "4px" }}>
                            {" "}
                            Closed
                          </span>
                        </li>
                      </ul>
                    </Col>
                  </Row>
                </CardBody>
              </Card>

              <Card className="card-lift--hover shadow border-0 mb-5">
                <CardBody className="py-4 text-justify">
                  <Row className="justify-content mt-2" id="foc10jan2022">
                    <Col lg={12} xs={12} className="text-justify">
                      <h6>
                        <b>
                        Advertisement for the post of Project Staff (JRF)
                        </b>
                      </h6>
                      <ul>
                        <li>
                        Applications are invited from Indian nationals for the post of Junior Research Fellow (JRF) on contract in a research project entitled "AITextGlasses: AI powered smart glasses for visually impaired persons to read newspaper and other multi-column text in Hindi language” sponsored by Ministry of Social Justice & Empowerment. The post is purely on contract basis for the maximum period of one year. 
                        </li>
                        <li>
                          <a href="https://files.iiitkota.ac.in/pdf/recruitment/Advertisement.pdf" target="_blank">
                            Advertisement
                          </a>
                        </li>
                        <li>
                          <a href="https://files.iiitkota.ac.in/pdf/recruitment/Application%20Form.pdf" target="_blank">
                            Application Form
                          </a>
                        </li>
                        <li>
                          <b>Closing Date:</b><del>4th April, 2024</del>
                          <span style={{ color: "red", marginLeft: "4px" }}>
                            {" "}
                            Closed
                          </span>
                        </li>
                      </ul>
                    </Col>
                  </Row>
                </CardBody>
              </Card>

              <Card className="card-lift--hover shadow border-0 mb-5">
                <CardBody className="py-4 text-justify">
                  <Row className="justify-content mt-2" id="foc10jan2022">
                    <Col lg={12} xs={12} className="text-justify">
                      <h6>
                        <b>
                          Advertisement for the post of Project Associate-I
                          (Purely on contract)
                        </b>
                      </h6>
                      <ul>
                        <li>
                          Applications are invited for the post of Project
                          Associate-I, purely on contract in a research project
                          entitled “Simulation Analysis and Implementation of
                          Soft Decision Forward Error Correction Codes for
                          High-Speed Free-Space/Satellite Optical Communication”
                          sponsored by the Indian Space Research Organization
                          (ISRO). Leading to an immense increment in
                          recruitments from previous batches. This deepens the
                          fact that we are moving forward with solid
                          determination and excellence, to reach even bigger
                          paramount.
                        </li>
                        <li>
                          <a href="https://files.iiitkota.ac.in/pdf/recruitment/Project Associate_Adv_RACS ISRO 10 Jan 2024.pdf" target="_blank">
                            Advertisement
                          </a>
                        </li>
                        <li>
                          <a href="https://forms.gle/CMtTwJgvbikJcPoi7" target="_blank">
                            Apply Online
                          </a>
                        </li>
                        <li>
                        <del>30th January, 2024</del>
                          <span style={{ color: "red", marginLeft: "4px" }}>
                            {" "}
                            Closed
                          </span>
                        </li>
                      </ul>
                    </Col>
                  </Row>
                </CardBody>
              </Card>

              <Card className="card-lift--hover shadow border-0 mb-5">
                <CardBody className="py-4 text-justify">
                  <Row className="justify-content mt-2" id="foc10jan2022">
                    <Col lg={12} xs={12} className="text-justify">
                      <h6>
                        <b>
                          Advertisement for the Engagement of Faculty on
                          Contract and Teaching Associates
                        </b>
                      </h6>
                      <ul>
                        <li>
                          Walk-in-interview for the post of Faculty on Contract
                          (purely on contract) for one year and Teaching
                          Associates for one semester at Indian Institute of
                          Information Technology (IIIT) Kota shall be held on
                          22nd October 2023.
                        </li>
                        <li>
                          <a href="https://files.iiitkota.ac.in/pdf/recruitment/Advertisment of Contract Faculty and TA's.pdf">
                            Advertisement
                          </a>
                        </li>
                        <li>
                          <b>Interview Date:</b>
                          <del>22nd October, 2023</del>
                          <span style={{ color: "red", marginLeft: "4px" }}>
                            {" "}
                            Closed
                          </span>
                        </li>
                      </ul>
                    </Col>
                  </Row>
                </CardBody>
              </Card>

              <Card className="card-lift--hover shadow border-0 mb-5">
                <CardBody className="py-4 text-justify">
                  <Row className="justify-content mt-2" id="foc10jan2022">
                    <Col lg={12} xs={12} className="text-justify">
                      <h6>
                        <b>
                          Advertisement for the Post of Project Staff (JRF)
                          (Purely on Contract)
                        </b>
                      </h6>
                      <ul>
                        <li>
                          Applications are invited from Indian nationals for the
                          post of Junior Research Fellow (JRF) on contract in a
                          research project entitled “Simulation Analysis and
                          Implementation of Soft Decision Forward Error
                          Correction Codes for High-Speed Free-Space/Satellite
                          Optical Communication” sponsored by the Indian Space
                          Research Organization (ISRO).
                        </li>                
                        <li>
                          <a href="https://files.iiitkota.ac.in/pdf/recruitment/JRF_Adv_RACS ISRO Amit Garg - Revised 11 Oct 23.pdf">
                            Advertisement
                          </a>
                        </li>
                        <li>
                          <b>Closing Date:</b>
                          <del>10th November, 2023</del>
                          <span style={{ color: "red", marginLeft: "4px" }}>
                            {" "}
                            Cancelled
                          </span>
                        </li>
                        <li>
                          This advertisement has been canceled; the candidates
                          who applied earlier need to apply again against the
                          fresh advertisement dated 10 Jan 2024
                        </li>
                      </ul>
                    </Col>
                  </Row>
                </CardBody>
              </Card>

              <Card className="card-lift--hover shadow border-0 mb-5">
                <CardBody className="py-4 text-justify">
                  <Row className="justify-content mt-2" id="foc10jan2022">
                    <Col lg={12} xs={12} className="text-justify">
                      <h6>
                        <b>Walk-in-interview for Teaching Associate</b>
                      </h6>
                      <ul>
                        <li>
                          Walk-in-interview for the post of Teaching Associate
                          in the department of Computer Science and Engineering,
                          Department of Electronics and Communication
                          Engineering and Humanities shall be held on 23rd
                          September, 2023. Interested applicants are required to
                          bring their resumes, relevant original documents with
                          Xerox copy in support of their age, qualifications,
                          experience, passport size photographs etc. at Indian
                          Institute of Information Technology Kota, SPL-269,
                          RIICO Industrial Area, Kuber Extension, Ranpur, KOTA
                          (Rajasthan) – 325003. The consolidated remuneration
                          for the post of Teaching Associate is Rs. 45000/- per
                          month. The appointment shall be made for the odd
                          semester of academic session 2023-24.
                        </li>
                        <li>
                          <a href="https://files.iiitkota.ac.in/pdf/Advertisment%20Teaching%20Associate.pdf">
                            Advertisement{" "}
                          </a>
                        </li>
                        <li>
                          <b>Interview Date</b>:
                          <del> 23rd September, 2023 .</del>
                          <span style={{ color: "red", marginLeft: "4px" }}>
                            {" "}
                            Closed
                          </span>
                        </li>
                      </ul>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
              <Card className="card-lift--hover shadow border-0 mb-5">
                <CardBody className="py-4 text-justify">
                  <Row className="justify-content mt-2" id="foc10jan2022">
                    <Col lg={12} xs={12} className="text-justify">
                      <h6>
                        <b>
                          Refund for Non-Teaching positions of Group A, B & C
                          vide Advertisement No. IIITK/2021-22/ESTT/01 dated
                          14.7.2021
                        </b>
                      </h6>
                      <ul>
                        <li>
                          The Institute had published various Non-Teaching
                          positions of Group A, B & C vide Advertisement No.
                          IIITK/2021-22/ESTT/01 dated 14.7.2021. The recruitment
                          for the above positions could not be executed due to
                          some administrative reasons and stands cancelled. With
                          the approval of Competent Authority, the application
                          fees paid by the applicants will be refunded to them.
                          All the applicants are advised to update their bank
                          details within 30 days from the date of issue of this
                          notice by login in the respective link on the website
                          for refund of application fees. No request of refund
                          will be entertained after the above due date.
                        </li>
                        <li>
                          <a href="">Refund Link</a>
                        </li>
                        <li>
                          <b>Closing Date</b>: <del>26th August, 2023</del>
                          <span style={{ color: "red", marginLeft: "4px" }}>
                            {" "}
                            Closed
                          </span>
                        </li>
                      </ul>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
              <Card className="card-lift--hover shadow border-0 mb-5">
                <CardBody className="py-4 text-justify">
                  <Row className="justify-content mt-2" id="foc10jan2022">
                    <Col lg={12} xs={12} className="text-justify">
                      <h6>
                        <b>
                          Applications are invited for the Post of Contract
                          Faculty (Assistant Professor)
                        </b>
                      </h6>
                      <ul>
                        <li>
                          Online applications are invited from Indian nationals
                          for engagement as Assistant Professor purely on
                          contract up to one year at Indian Institute of
                          Information Technology (IIIT) Kota.
                        </li>
                        <li>
                          <a href="https://files.iiitkota.ac.in/pdf/recruitment/Contract%20Faculty(IIIT%20Kota)2023.pdf">
                            Advertisement{" "}
                          </a>
                        </li>
                        <li>
                          {/* <a href="https://recruitment.mnit.ac.in/IIITK/2301/"> */}
                          Apply Online
                          {/* </a> */}
                        </li>
                        <li>
                          <b>Closing Date</b>: <del>24th July, 2023</del>
                          <span style={{ color: "red", marginLeft: "4px" }}>
                            {" "}
                            Closed
                          </span>
                        </li>
                        <li>Interview call letters sent through e-mail</li>
                      </ul>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
              <Card className="card-lift--hover shadow border-0 mb-5">
                <CardBody className="py-4 text-justify">
                  <Row className="justify-content mt-2" id="foc10jan2022">
                    <Col lg={12} xs={12} className="text-justify">
                      <h6>
                        <b>
                          Advertisement for the Post of Project Staff (JRF)
                          (Purely on Contract)
                        </b>
                      </h6>
                      <ul>
                        <li>
                          Applications are invited from Indian nationals for the
                          post of Junior Research Fellow (JRF) on contractin a
                          research project entitled “Design and Development of
                          IoMT based Anthropomorphic Robotic Glove for Stress
                          Detection and Aiding Hand Disabled People” sponsored
                          by Bharat Electronics Limited (BEL).
                        </li>
                        <li>
                          <a href="https://files.iiitkota.ac.in/pdf/recruitment/Advertisement%20with%20Application%20Form%20BEL.pdf">
                            Advertisement{" "}
                          </a>
                        </li>
                        <li>
                          {/* <a href="https://docs.google.com/forms/d/e/1FAIpQLSfsAda7LncF2vslc7_uR_t2P9EPI-tOJksIoOnIyRtmBUa6Og/viewform?vc=0&c=0&w=1&flr=0"> */}
                          Apply Online
                          {/* </a> */}
                        </li>
                        <li>
                          <b>Closing Date</b>: <del>4th August, 2023</del>
                          <span style={{ color: "red", marginLeft: "4px" }}>
                            {" "}
                            Closed
                          </span>
                        </li>
                      </ul>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
              <Card className="card-lift--hover shadow border-0 mb-5">
                <CardBody className="py-4 text-justify">
                  <Row className="justify-content mt-2" id="foc10jan2022">
                    <Col lg={12} xs={12} className="text-justify">
                      <h6>
                        <b>
                          Applications are invited for the post of Junior
                          Research Fellow purely on contract.
                        </b>
                      </h6>
                      <ul>
                        <li>
                          Applications are invited for the post of Junior
                          Research Fellow purely on contract in a research
                          project entitled "Simulation Analysis and
                          Implementation of Soft Decision Forward Error
                          Correction Codes for High-Speed Free-Space/Satellite
                          Optical Communication" sponsored by the Indian Space
                          Research Organization (ISRO).
                        </li>
                        <li>
                          <a href="https://files.iiitkota.ac.in/pdf/JRF_Adv_RACS%20ISRO%20Amit%20Garg%20-%20Revised%2017%20May%2023.pdf">
                            Advertisement{" "}
                          </a>
                        </li>
                        <li>
                          <a href="#">Apply Online</a>
                        </li>
                        <li>
                          <b>Closing Date</b>: <del>16th June, 2023</del>
                          <span style={{ color: "red", marginLeft: "4px" }}>
                            {" "}
                            Closed
                          </span>
                        </li>
                      </ul>
                    </Col>
                  </Row>
                </CardBody>
              </Card>

              <Card className="card-lift--hover shadow border-0 mb-5">
                <CardBody className="py-4 text-justify">
                  <Row className="justify-content mt-2" id="foc10jan2022">
                    <Col lg={12} xs={12} className="text-justify">
                      <h6>
                        <b>
                          Applications are invited for the post of Assistant
                          Registrar on deputation basis.
                        </b>
                      </h6>
                      <ul>
                        <li>
                          <a href="https://files.iiitkota.ac.in/images/advertisement/advt.jpg">
                            Short advertisement{" "}
                          </a>
                        </li>
                        <li>
                          <a href="https://files.iiitkota.ac.in/pdf/RecruitmentNotification-AR_Deputation.pdf">
                            Advertisement{" "}
                          </a>
                        </li>
                        {/* <li>
                          <a href="https://recruitment.mnit.ac.in/IIITKOTA/Ar/">Application form</a>
                        </li> */}
                        <li>
                          {/* <b>Closing Date</b>: 7th April, 2023 */}
                          <b>Closed</b>
                        </li>
                      </ul>
                    </Col>
                  </Row>
                </CardBody>
              </Card>

              <Card className="card-lift--hover shadow border-0 mb-5">
                <CardBody className="py-4 text-justify">
                  <Row className="justify-content mt-2" id="foc10jan2022">
                    <Col lg={12} xs={12} className="text-justify">
                      <h6>
                        <b>
                          Advertisement for the post of Junior Research Fellow
                          (On contract)
                        </b>
                      </h6>
                      <ul>
                        <li>
                          Applications are invited for the post of Junior
                          Research Fellow purely on contract in a research
                          project entitled “Simulation Analysis and
                          Implementation of Soft Decision Forward Error
                          Correction Codes for High-Speed Free-Space/Satellite
                          Optical Communication” sponsored by the Indian Space
                          Research Organization (ISRO).
                        </li>
                        <li>
                          <a href="https://files.iiitkota.ac.in/pdf/JRF_Adv_RACS%20ISRO%20JRF%20Purely%20on%20Contract.pdf">
                            Advertisement
                          </a>
                        </li>
                        {/* <li>
                          <a href="https://forms.gle/dz4p3TKVpFnMJudT7">Online application</a>
                        </li> */}
                        <li>
                          Applicants are advised to read the detailed
                          advertisement before submission.
                        </li>
                        <li>
                          {/* <b>Closing Date</b>: 5th April, 2023 */}
                          <b>Closed</b>
                        </li>
                      </ul>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
              <Card className="card-lift--hover shadow border-0 mb-5">
                <CardBody className="py-4 text-justify">
                  <Row className="justify-content mt-2" id="foc10jan2022">
                    <Col lg={12} xs={12} className="text-justify">
                      <h6>
                        <b>
                          Advertisement for the post of Junior Research Fellow
                          (On contract)
                        </b>
                      </h6>
                      <ul>
                        <li>
                          Advertisement for the post of Junior Research Fellow
                          (On contract) on sponsored research project funded
                          titled "Social media analysis using explainable
                          artificial intelligence(AI) based techniques for
                          less-resourced languages " by Government of Rajasthan.
                        </li>
                        <li>
                          <a href="https://files.iiitkota.ac.in/pdf/Advertisement_RISL_socialMedia%20final.pdf">
                            Advertisement
                          </a>
                        </li>
                        <li>
                          <b>Closed</b>
                        </li>
                      </ul>
                    </Col>
                  </Row>
                </CardBody>
              </Card>

              <Card className="card-lift--hover shadow border-0 mb-5">
                <CardBody className="py-4 text-justify">
                  <Row className="justify-content mt-2" id="foc10jan2022">
                    <Col lg={12} xs={12} className="text-justify">
                      <h6>
                        <b>
                          Advertisement for Assistant Professor on Contract.
                        </b>
                      </h6>
                      <ul>
                        <li>
                          <a href="https://files.iiitkota.ac.in/pdf/recruitment/Advertisement%20for%20Assistant%20Professor%20on%20contract_%20IIIT%20Kota.pdf">
                            Advertisement
                          </a>
                        </li>
                        <li>
                          <b>Closed</b>
                        </li>
                      </ul>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
              <Card className="card-lift--hover shadow border-0 mb-5">
                <CardBody className="py-4 text-justify">
                  <Row className="justify-content mt-2" id="foc10jan2022">
                    <Col lg={12} xs={12} className="text-justify">
                      <h6>
                        <b>
                          Advertisement for the post of Junior Research Fellow
                          (On contract)
                        </b>
                      </h6>
                      <ul>
                        <li>
                          Advertisement for the post of Junior Research Fellow
                          (On contract) on sponsored research project funded
                          titled " Social media analysis using explainable
                          artificial intelligence(AI) based techniques for
                          less-resourced languages " by Government of Rajasthan.
                        </li>
                        <li>
                          <a href="https://files.iiitkota.ac.in/pdf/Advertisement_RISL_socialMedia.pdf">
                            Advertisement
                          </a>
                        </li>
                        <li>
                          <b>Closed</b>
                        </li>
                      </ul>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
              <Card className="card-lift--hover shadow border-0 mb-5">
                <CardBody className="py-4 text-justify">
                  <Row className="justify-content mt-2" id="foc10jan2022">
                    <Col lg={12} xs={12} className="text-justify">
                      <h6>
                        <b>
                          Advertisement for the posts of Assistant Registrar
                          (Accounts, Finance & Procurement, Establishment &
                          Administration) on deputation basis.
                        </b>
                      </h6>
                      <ul>
                        <li>
                          <a href="https://files.iiitkota.ac.in/pdf/Advt_Assistant%20Registrar%20on%20deputation%20basis%20in%20IIIT%20Kota.pdf">
                            Advertisement
                          </a>
                        </li>
                        <li>
                          <a href="https://files.iiitkota.ac.in/pdf/Corrigendum.pdf">
                            Corrigendum
                          </a>
                        </li>
                        <li>
                          <a href="https://files.iiitkota.ac.in/pdf/Application_Form_Assistant%20Registrar%20on%20deputation%20basis%20in%20IIIT%20Kota.pdf">
                            Application Form
                          </a>
                        </li>
                        <li>
                          <b>Closed</b>
                        </li>
                      </ul>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
              </>
                          :""}
             


              {/* <Card className="card-lift--hover shadow border-0 mb-5">
                <CardBody className="py-2 text-justify text-center">
                  <Row className="justify-content-center mt-5" id="foc10jan2022">
                    <Col lg={12} xs={12} className="text-justify">
                      <center>
                        <h6>
                          <b>
                            Advertisement for the post of Junior Research Fellowon
                            sponsored research project funded titled "Technology
                            dissemination through massive open online courses for
                            skill enhancement and supporting higher education with
                            advanced learning analytics for effective teaching " by
                            Government of Rajasthan.
                          </b>
                          <b style={{ color: "red", fontWeight: "900" }}>
                            {" "}
                            ( Closed )
                          </b>
                        </h6>
                      </center>
                    </Col>
                  </Row>
                  <Row className="justify-content-center mt-3 mb-4">
                    <Col lg="12">
                      <Row className="row-grid justify-content-center">
                        <Col lg="6">
                          <Card className="border border-secondary border-1">
                            <CardBody className="py-5 text-justify text-center">
                              <div className="icon icon-shape icon-shape-primary rounded-circle mb-4">
                                <a href="https://files.iiitkota.ac.in/pdf/Advertisement_RISL_elda.pdf">
                                  <i className="fas fa-external-link-alt"></i>
                                </a>
                              </div>

                              <h6>
                                <a href="https://files.iiitkota.ac.in/pdf/Advertisement_RISL_elda.pdf">
                                  <b>JRF Advertisement</b>
                                </a>
                              </h6>
                            </CardBody>
                          </Card>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </CardBody>
              </Card>

              <Card className="card-lift--hover shadow border-0 mb-5">
                <CardBody className="py-2 text-justify text-center">
                  <Row className="justify-content-center mt-5" id="foc10jan2022">
                    <Col lg={12} xs={12} className="text-justify">
                      <center>
                        <h6>
                          <b>
                            Applications are invited from Indian nationals for the
                            post of Junior research Fellow (JRF) on contractin
                            research project entitled “Early and automated diagnosis
                            of dysgraphia using deep neural network” sponsored by
                            Department of Science and Technology, New Delhi for upto
                            31/03/2023 which may be extended further until the
                            project is completed.
                          </b>
                          <b style={{ color: "red", fontWeight: "900" }}>
                            {" "}
                            ( Closed )
                          </b>
                        </h6>
                      </center>
                    </Col>
                  </Row>
                  <Row className="justify-content-center mt-3 mb-4">
                    <Col lg="12">
                      <Row className="row-grid justify-content-center">
                        <Col lg="6">
                          <Card className="border border-secondary border-1">
                            <CardBody className="py-5 text-justify text-center">
                              <div className="icon icon-shape icon-shape-primary rounded-circle mb-4">
                                <a href="https://files.iiitkota.ac.in/pdf/JRF_Adv_DST.pdf">
                                  <i className="fas fa-external-link-alt"></i>
                                </a>
                              </div>

                              <h6>
                                <a href="https://files.iiitkota.ac.in/pdf/JRF_Adv_DST.pdf">
                                  <b>JRF Advertisement</b>
                                </a>
                              </h6>
                            </CardBody>
                          </Card>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
              <Card className="card-lift--hover shadow border-0 mb-5">
                <CardBody className="py-2 text-justify text-center">
                  <Row className="justify-content-center mt-5" id="foc10jan2022">
                    <Col lg={12} xs={12} className="text-justify">
                      <center>
                        <h6>
                          <b>
                            Applications are invited from the Indian nationals for
                            the post of Junior Research Fellow (Purely contractual)
                            under SERB-SRG sponsored project titled “Emulation
                            Prototype and Testbed for 3D-mmWave Channel
                            Characterization at 60 GHz under the Real World Scenario
                            and Flexible Integration with 5GNR” in the department of
                            Electronics and Communications Engineering at IIIT Kota,
                            India for the period of 12 months.
                          </b>
                          <b style={{ color: "red", fontWeight: "900" }}>
                            {" "}
                            ( Closed )
                          </b>
                        </h6>
                      </center>
                    </Col>
                  </Row>
                  <Row className="justify-content-center mt-3 mb-4">
                    <Col lg="12">
                      <Row className="row-grid justify-content-center">
                        <Col lg="6">
                          <Card className="border border-secondary border-1">
                            <CardBody className="py-5 text-justify text-center">
                              <div className="icon icon-shape icon-shape-primary rounded-circle mb-4">
                                <a href="https://files.iiitkota.ac.in/pdf/JRF%20advertisement.pdf">
                                  <i className="fas fa-external-link-alt"></i>
                                </a>
                              </div>

                              <h6>
                                <a href="https://files.iiitkota.ac.in/pdf/JRF%20advertisement.pdf">
                                  <b>JRF Advertisement</b>
                                </a>
                              </h6>
                            </CardBody>
                          </Card>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
              <Card className="card-lift--hover shadow border-0 mb-5">
                <CardBody className="py-2 text-justify text-center">
                  <Row className="justify-content-center mt-5" id="foc10jan2022">
                    <Col lg={12} xs={12} className="text-justify">
                      <center>
                        <h6>
                          <b>
                            List of Eligible and Not-Eligible Candidates for the
                            post of Assistant Registrar against Advertisement No.
                            IIITK/2021-22/ESTT/01 dated 14.07.2021.
                          </b>
                          <b style={{ color: "red", fontWeight: "900" }}>
                            {" "}
                            ( Closed )
                          </b>
                        </h6>
                      </center>
                    </Col>
                    <h5 className="mt-5" style={{ color: "red" }}>
                      The schedule for the written test and interview will be
                      notified soon.
                    </h5>
                  </Row>
                  <Row className="justify-content-center mt-3 mb-4">
                    <Col lg="12">
                      <Row className="row-grid justify-content-center">
                        <Col lg="6">
                          <Card className="border border-secondary border-1">
                            <CardBody className="py-5 text-justify text-center">
                              <div className="icon icon-shape icon-shape-primary rounded-circle mb-4">
                                <a href="https://files.iiitkota.ac.in/pdf/Eligible%20list%20for%20Assistant%20Registrar.pdf">
                                  <i className="fas fa-external-link-alt"></i>
                                </a>
                              </div>

                              <h6>
                                <a href="https://files.iiitkota.ac.in/pdf/Eligible%20list%20for%20Assistant%20Registrar.pdf">
                                  <b>Eligible list for Assistant Registrar</b>
                                </a>
                              </h6>
                            </CardBody>
                          </Card>
                        </Col>
                        <Col lg="6">
                          <Card className="border border-secondary border-1">
                            <CardBody className="py-5 text-justify text-center">
                              <div className="icon icon-shape icon-shape-primary rounded-circle mb-4">
                                <a href="https://files.iiitkota.ac.in/pdf/List%20of%20not%20eligible%20for%20AR.pdf">
                                  <i className="fas fa-external-link-alt"></i>
                                </a>
                              </div>

                              <h6>
                                <a href="https://files.iiitkota.ac.in/pdf/List%20of%20not%20eligible%20for%20AR.pdf">
                                  <b>List of not eligible for AR</b>
                                </a>
                              </h6>
                            </CardBody>
                          </Card>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                  <Row className="justify-content-center mt-5 mb-5">
                    <Col lg="12">
                      <Row className="row-grid justify-content-center">
                        <Col lg="6">
                          <Card className="border border-secondary border-1">
                            <CardBody className="py-5 text-justify text-center">
                              <div className="icon icon-shape icon-shape-primary rounded-circle mb-4">
                                <a href="https://files.iiitkota.ac.in/pdf/Syllabus%20for%20Assistant%20Registrar.pdf">
                                  <i className="fas fa-external-link-alt"></i>
                                </a>
                              </div>

                              <h6>
                                <a href="https://files.iiitkota.ac.in/pdf/Syllabus%20for%20Assistant%20Registrar.pdf">
                                  <b>Syllabus for Assistant Registrar</b>
                                </a>
                              </h6>
                            </CardBody>
                          </Card>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </CardBody>
              </Card>

              <Card className="card-lift--hover shadow border-0 mb-5">
                <CardBody className="py-2 text-justify text-center">
                  <Row className="justify-content-center mt-5" id="foc10jan2022">
                    <Col lg={12} xs={12} className="text-justify">
                      <center>
                        <h6>
                          <b>
                            Advertisement for the Recruitment For Various
                            Non-Teaching Posts in IIIT Kota Advt. No.:
                            IIITK/2021-22/ESTT/01 (14 July 2021)
                          </b>
                          <b style={{ color: "red", fontWeight: "900" }}>
                            {" "}
                            ( Closed )
                          </b>
                        </h6>
                      </center>
                    </Col>
                  </Row>
                  <Row className="justify-content-center mt-3 mb-4">
                    <Col lg="12">
                      <Row className="row-grid justify-content-center">
                        <Col lg="6">
                          <Card className="border border-secondary border-1">
                            <CardBody className="py-5 text-justify text-center">
                              <div className="icon icon-shape icon-shape-primary rounded-circle mb-4">
                                <a href="https://files.iiitkota.ac.in/pdf/ADVERTISEMENT%20-%20IIIT%20Kota%20for%20NON%20TEACHING%202021.pdf">
                                  <i className="fas fa-external-link-alt"></i>
                                </a>
                              </div>

                              <h6>
                                <a href="https://files.iiitkota.ac.in/pdf/ADVERTISEMENT%20-%20IIIT%20Kota%20for%20NON%20TEACHING%202021.pdf">
                                  <b>Advertisement</b>
                                </a>
                              </h6>
                            </CardBody>
                          </Card>
                        </Col>
                        <Col lg="6">
                          <Card className="border border-secondary border-1">
                            <CardBody className="py-5 text-justify text-center">
                              <div className="icon icon-shape icon-shape-primary rounded-circle mb-4">
                                <a href="http://recruitment.mnit.ac.in/IIITKOTA/">
                                  <i className="fas fa-external-link-alt"></i>
                                </a>
                              </div>

                              <h6>
                                <a href="http://recruitment.mnit.ac.in/IIITKOTA/">
                                  <b>Online Application Form.</b>
                                </a>
                              </h6>
                            </CardBody>
                          </Card>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </CardBody>
              </Card>

              <Card className="card-lift--hover shadow border-0 mb-5">
                <CardBody className="py-2 text-justify text-center">
                  <Row className="justify-content-center mt-5" id="foc10jan2022">
                    <Col lg={12} xs={12} className="text-justify">
                      <center>
                        <h6>
                          <b>
                            Advertisement for Faculty on contract (Purely on
                            contract for a period of one year) of IIIT Kota (28 June
                            2021)
                          </b>
                          <b style={{ color: "red", fontWeight: "900" }}>
                            {" "}
                            ( Closed )
                          </b>
                        </h6>
                      </center>
                    </Col>
                  </Row>
                  <Row className="justify-content-center mt-3 mb-4">
                    <Col lg="12">
                      <Row className="row-grid justify-content-center">
                        <Col lg="6">
                          <Card className="border border-secondary border-1">
                            <CardBody className="py-5 text-justify text-center">
                              <div className="icon icon-shape icon-shape-primary rounded-circle mb-4">
                                <a href="https://files.iiitkota.ac.in/pdf/Website_Contract%20Faculty%20Advt%202021.pdf">
                                  <i className="fas fa-external-link-alt"></i>
                                </a>
                              </div>

                              <h6>
                                <a href="https://files.iiitkota.ac.in/pdf/Website_Contract%20Faculty%20Advt%202021.pdf">
                                  <b>Advertisement</b>
                                </a>
                              </h6>
                            </CardBody>
                          </Card>
                        </Col>
                        <Col lg="6">
                          <Card className="border border-secondary border-1">
                            <CardBody className="py-5 text-justify text-center">
                              <div className="icon icon-shape icon-shape-primary rounded-circle mb-4">
                                <a href="https://files.iiitkota.ac.in/pdf/Application%20Form.pdf">
                                  <i className="fas fa-external-link-alt"></i>
                                </a>
                              </div>

                              <h6>
                                <a href="https://files.iiitkota.ac.in/pdf/Application%20Form.pdf">
                                  <b>Application Form</b>
                                </a>
                              </h6>
                            </CardBody>
                          </Card>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </CardBody>
              </Card>

              <Card className="card-lift--hover shadow border-0 mb-5">
                <CardBody className="py-2 text-justify text-center">
                  <Row className="justify-content-center mt-5" id="jrf">
                    <Col lg={12} xs={12} className="text-justify">
                      <center>
                        <h6>
                          <b>
                            Advertisement for the Post for Project Staff (JRF) (On
                            Contract) (23 June 2021)
                          </b>
                          <b style={{ color: "red", fontWeight: "900" }}>
                            {" "}
                            ( Closed )
                          </b>
                        </h6>
                      </center>
                    </Col>
                  </Row>
                  <Row className="justify-content-center mt-3 mb-4">
                    <Col lg="12">
                      <Row className="row-grid justify-content-center">
                        <Col lg="6">
                          <Card className="border border-secondary border-1">
                            <CardBody className="py-5 text-justify text-center">
                              <div className="icon icon-shape icon-shape-primary rounded-circle mb-4">
                                <a href="https://files.iiitkota.ac.in/pdf/JRF_Re-Advertisemnet.pdf">
                                  <i className="fas fa-external-link-alt"></i>
                                </a>
                              </div>

                              <h6>
                                <a href="https://files.iiitkota.ac.in/pdf/JRF_Re-Advertisemnet.pdf">
                                  <b>Download PDF</b>
                                </a>
                              </h6>
                            </CardBody>
                          </Card>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </CardBody>
              </Card>

              <Card className="card-lift--hover shadow border-0 mb-5">
                <CardBody className="py-2 text-justify text-center">
                  <Row className="justify-content-center mt-5" id="foc10jan2022">
                    <Col lg={12} xs={12} className="text-justify">
                      <h6>
                        List of Selected Candidates for the post of Assistant
                        Professor in AGP 6000 (Level 10 under 7 CPC) at IIIT Kota
                        (MNIT Campus) Against Advertisement No.
                        01/TEACHING/IIITKOTA/ESTT/2019.
                      </h6>
                    </Col>
                    <Col lg={12} xs={12} className="text-justify">
                      <h6>
                        <b>List of Selected Candidates department wise:</b>
                      </h6>
                    </Col>
                  </Row>
                  <Row className="justify-content-center mt-3 mb-4">
                    <Col lg="12">
                      <Row className="row-grid justify-content-center">
                        <Col lg="6">
                          <Card className="border border-secondary border-1">
                            <CardBody className="py-5 text-justify text-center">
                              <div className="icon icon-shape icon-shape-primary rounded-circle mb-4">
                                <a href="https://files.iiitkota.ac.in/pdf/recruitment/CSE_Result.pdf">
                                  <i className="fas fa-external-link-alt"></i>
                                </a>
                              </div>

                              <h6>
                                <a href="https://files.iiitkota.ac.in/pdf/recruitment/CSE_Result.pdf">
                                  <b>Computer Science and Engineering</b>
                                </a>
                              </h6>
                            </CardBody>
                          </Card>
                        </Col>
                        <Col lg="6">
                          <Card className="border border-secondary border-1">
                            <CardBody className="py-5 text-justify text-center">
                              <div className="icon icon-shape icon-shape-primary rounded-circle mb-4">
                                <a href="https://files.iiitkota.ac.in/pdf/recruitment/ECE_Result.pdf">
                                  <i className="fas fa-external-link-alt"></i>
                                </a>
                              </div>

                              <h6>
                                <a href="https://files.iiitkota.ac.in/pdf/recruitment/ECE_Result.pdf">
                                  <b>Electronics and Communication Engineering </b>
                                </a>
                              </h6>
                            </CardBody>
                          </Card>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </CardBody>
              </Card> */}
            </Container>
          </section>
        </main>

        <NewFooter />
      </>
    );
  }
}

export default Recruitment;

import React from "react";
import "assets/css/style.css";
import { Button, Container, Row, Col, Card, CardBody } from "reactstrap";
import DemoNavbar from "components/Navbars/DemoNavbar.js";
import NewFooter from "components/Footers/NewFooter.js";
import Helmet from "react-helmet";
class UgProcedure extends React.Component {
  state = {};
  componentDidMount() {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    this.refs.main.scrollTop = 0;
  }
  render() {
    return (
      <>
        <Helmet>
          <title>
            Admission Procedure - Indian Institute of Information Technology,
            Kota
          </title>
          <meta
            name="description"
            content="Admission Procedure - Indian Institute of Information Technology, Kota"
          />
          <meta
            name="keywords"
            content="Admission Procedure iiit kota, josaa, admissions in iiitkota, councelling, cutoff iiitkota, csab,IIIT Kota, IIIT, Top IIITs,Indian Institute of Information Technology Kota"
          />
        </Helmet>
        <DemoNavbar />
        <main ref="main">
          <div className="position-relative">
            {/* shape Hero */}
            <section className="section  section-shaped pb-250">
              <div className="shape shape-style-1 shape-default bg-gradient-default">
                <span />
                <span />
                <span />
                <span />
                <span />
                <span />
                <span />
                <span />
                <span />
              </div>

              <div className="separator separator-bottom separator-skew">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  preserveAspectRatio="none"
                  version="1.1"
                  viewBox="0 0 2560 100"
                  x="0"
                  y="0"
                >
                  <polygon className="fill-white" points="0 0 0 0 0 0" />
                </svg>
              </div>
            </section>
          </div>
          <section className="section section-lg">
            <Container>
              <Row className="justify-content-center text-center">
                <Col lg="10">
                  <h2>
                    <b>B.Tech Admissions</b>
                  </h2>
                  <h2>
                    <b>2024-25</b>
                    <b>
                      <span style={{ color: "red", marginLeft: "4px" }}>
                        {" "}
                        Closed
                      </span>
                    </b>
                  </h2>
                  {/* <h4>
                    <b>Physical Reporting 4th November to 8th November, 2022</b>
                  </h4> */}

                  <br />
                  {/* <h5>
                    <b>
                      Keep checking the JoSAA/CSAB and IIIT Kota websites
                      regularly for getting latest updates for UG admission
                      2022-23 batch
                    </b>
                  </h5> */}
                  <br />
                  {/* <h6>
                    <b>
                      INSTRUCTIONS TO CANDIDATES FOR ADMISSION IN B.TECH.
                      PROGRAMME 2021-22 AT IIIT KOTA THROUGH JoSAA/CSAB
                    </b>
                  </h6> */}
                </Col>
              </Row>
              {/* <Row className="justify-content-center text-justify">
                <Col lg={12} xs={12}>
                  <h6>
                    <b>NOTE-1:-</b> Students are required to go through the
                    JoSAA and CSAB portals for any important updates.
                    <br />
                    <br />
                    <b>NOTE-2:-</b> Join the given GoogleMeet link for any query
                    related to IIIT Kota Application Form / Fees / Documents.{" "}
                    <a href="https://meet.google.com/ojh-wfdx-zwh">
                      Click here to join the GoogleMeet.
                    </a>
                  </h6>
                   <h6>
                    As per new instructions from CSAB, the institute reporting for new B.Tech
                    admission 2021-22 shall be done during Dec 7-13, 2021.
                    <br />
                    Students need to visit JoSAA and CSAB websites regularly to pay seat
                    acceptance fee, partial admission fee and other important notifications.
                    <br />
                    Students also need to visit IIIT Kota website to get updates.
                  </h6> 
                </Col>
              </Row> */}
              <Card className="card-lift--hover shadow border-0 mb-5">
                <CardBody className="py-4 text-justify">
                  <Row className="justify-content mt-2" id="foc10jan2022">
                    <Col lg={12} xs={12} className="text-justify">
                      <h6>
                        <b>
                          B.Tech in Computer Science and Engineering /
                          Electronics and Communication Engineering / Artificial Intelligence and Data Engineering
                        </b>
                      </h6>
                      <ul>
                        {/* <li>
                          Applications are invited for the post of Junior
                          Research Fellow purely on contract in a research
                          project entitled "Simulation Analysis and
                          Implementation of Soft Decision Forward Error
                          Correction Codes for High-Speed Free-Space/Satellite
                          Optical Communication" sponsored by the Indian Space
                          Research Organization (ISRO).
                        </li> */}
                        <li>
                          Physical reporting Date: 10<sup>th</sup>Aug 2024 - 5<sup>th</sup> Sep 2024{/* 16<sup>th</sup> - 21 */}
                          {/* <sup>st</sup> August */}
                        </li>
                        <li>
                          Physical reporting Venue : IIIT Kota, SPL-269, RIICO
                          Industrial Area, Kuber Extension, Ranpur, Kota –
                          325003, Rajasthan{" "}
                          <a
                            rel="noopener noreferrer"
                            target="_blank"
                            href="https://www.google.com/maps/place/IIIT+Kota+permanent+campus/@25.0498253,74.7252207,9z/data=!4m10!1m2!2m1!1siiit+kota+kota!3m6!1s0x396f874a7d8fde0d:0x471f3c2337f358e8!8m2!3d25.0498253!4d75.8267351!15sCg5paWl0IGtvdGEga290YZIBBnNjaG9vbOABAA!16s%2Fg%2F11gy9z31n0?entry=ttu"
                          >
                            Google Map
                          </a>
                        </li>
                        <li>
                          List of Required Documents and other Important Information:{" "}

                          <a
                            rel="noopener noreferrer"
                            target="_blank"
                            href="https://files.iiitkota.ac.in/pdf/ug/Updated-List-of-required-documents-2024.pdf"
                          >
                            Link
                          </a>

                        </li>
                        <li>
                          Fee Structure :{" "}
                          <a
                            rel="noopener noreferrer"
                            target="_blank"
                            href="https://files.iiitkota.ac.in/pdf/fees/Fee_Structure_2024-25.pdf"
                          >
                            Institute Fees
                          </a>
                          {" and "}
                          <a

                            target="_blank"
                            href="https://files.iiitkota.ac.in/pdf/ug/Hostel Fees 2024-25.pdf">
                            Hostel Fees
                          </a>
                        </li>
                        <li>
                          Procedure for Fees (Institute and Hostel) Payment :{" "}

                          <a
                            rel="noopener noreferrer"
                            target="_blank"
                            href="https://files.iiitkota.ac.in/pdf/ug/Fee%20page.pdf"
                          >
                            Link
                          </a>

                        </li>
                        <li>
                          Contact Details :{" "}

                          <a
                            rel="noopener noreferrer"
                            target="_blank"
                            href="https://files.iiitkota.ac.in/pdf/ug/Help.pdf"

                          >
                            Link
                          </a>

                        </li>
                        <li>
                          JoSSA 2024 :{" "}
                          <a
                            rel="noopener noreferrer"
                            target="_blank"
                            href="https://josaa.nic.in/"
                          >
                            Link
                          </a>
                        </li>
                        {/* <li>
                          JOSSA 2023 :{" "}
                          <a href="https://csab.nic.in/">
                            Counselling Schedule
                          </a>
                        </li> */}
                        <li>
                          CSAB 2024 : <a href="https://csab.nic.in/">Link</a>
                        </li>
                        {/* <li>
                          <a href="#">Information Brochure</a>
                        </li> */}
                        {/* <li>
                          <b>Closing Date</b>: 16th June, 2023
                          <b>Closed</b>
                        </li>  */}
                      </ul>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
              {/* <Row className="justify-content-center text-justify">
                <Col lg={12} xs={12}>
                  <h5>
                    <a href="https://files.iiitkota.ac.in/pdf/Instruction.pdf">
                      Instruction for Fee Payment &nbsp;&nbsp;&nbsp;
                    </a>
                    {"    "}
                    <a
                      href="https://www.onlinesbi.sbi/sbicollect/icollecthome.htm?corpID=646985"
                      className="feePayment"
                    >
                      Link for Fee Payment &nbsp;&nbsp;&nbsp;
                    </a>
                    <a href="https://forms.gle/5rnNHwcUDPNVfA1E8">
                      Update Fee Details
                    </a>

                    <br />
                    <br />
                    <b>
                      Bring the following documents in original and one set of
                      photocopy along with parents/guardian at the time of
                      institute physical reporting (at IIIT Kota office, 2nd
                      Floor Prabha Bhawan, MNIT Campus Jaipur):
                    </b>
                  </h5>
                </Col>
                <Col lg={12} xs={12} className="mt-3">
                  <h6>
                    <ol>
                      <li>
                        Provisional seat allotment letter issued by JoSAA/CSAB
                        2022
                      </li>
                      <li>JEE Main Score Card (from which seat is allotted)</li>
                      <li>JEE Main Admit Card (from which seat is allotted)</li>
                      <li>
                        Proof of Date of Birth (10th Certificate and Mark Sheet)
                      </li>
                      <li>
                        Qualifying Examination (12th Certificate and Mark Sheet)
                      </li>
                      <li>
                        Character Certificate (from the last attended
                        school/institute)
                      </li>
                      <li>
                        Transfer Certificate (i.e. TC) (from the last attended
                        school/institute)
                      </li>
                      <li>
                        Migration Certificate (from the last attended
                        school/institute)
                      </li>
                      <li>Medical Certificate as per JoSAA-2022 format</li>
                      <li>
                        Category Certificate (SC/ST/OBC-NCL/Gen-EWS) as per
                        JoSAA-2022 format (if applicable)
                      </li>
                      <li>Domicile Certificate</li>
                      <li>PwD Certificate (if applicable)</li>
                      <li>Aadhar Card</li>
                      <li>
                        One ID Proof other than Aadhar Card such as PAN
                        Card/Voter ID Card/Driving License/or any other Govt.
                        issued ID Card
                      </li>
                      <li>
                        Notarized affidavit on non-judicial stamp (Rs. 10 or
                        above) for Gap (if there is a gap after passing the
                        qualifying examination)
                        <a
                          href="https://files.iiitkota.ac.in/pdf/Affidavit%20for%20Gap.pdf"
                          target="_blank"
                        >
                          ( Click here to see the Affidavit for Gap )
                        </a>
                      </li>
                      <li>JoSAA/CSAB Fee Payment Receipts</li>
                      <li>
                        Institute / Hostel Fee Payment Details{" "}
                        <a href="https://files.iiitkota.ac.in/pdf/Fee_Structure_2022-23_New.pdf">
                          ( Click here to see Institute fee structure )
                        </a>{" "}
                        <a href="https://files.iiitkota.ac.in/pdf/fees/Hostel_Fee_New.pdf">
                          ( Click here to see Hostel fee structure )
                        </a>
                        <br />
                      </li>
                    </ol>
                    <br />
                  </h6>
                </Col>
                <Col lg={12} xs={12}>
                  <h5>
                    <b>For fee related help, you can contact</b>
                  </h5>
                  <h6>
                    <ol>
                      <li>Mr. Parag Somani – 0141-2715070, 9549650547</li>
                      <li>Mr. Pratap Singh – 9549650189</li>
                      <li>Mr. Satyanarayan Sharma - 9549651643</li>
                      <li>
                        You can also join the given GoogleMeet link for fee
                        related issues 
                        <a href="https://meet.google.com/ojh-wfdx-zwh">
                          (Click here to join the Google Meeting)
                        </a>
                      </li>
                    </ol>
                  </h6>
                  <br />

                  <h5>
                    <b>For documents related help, you can contact</b>
                  </h5>
                  <h6>
                    <ol>
                      <li>Office – 0141-2715071</li>
                      <li>Mr. Tej Singh Jatav – 9549655861</li>
                      <li>Mr. Banne Singh Bhati – 9549650589</li>
                      <li>Mr. Satyanarayan Sharma - 9549651643</li>
                      <li>Dr. Amit Kumar – 9549655278</li>
                      <li>Dr. Ajay Nehra - 9549652321 </li>
                    </ol>
                  </h6>
                  <br />

                  <h5>
                    <b>
                      For commencement of classes and other academic queries,
                      Please contact
                    </b>
                  </h5>
                  <h6>
                    <b>
                      {" "}
                      B.Tech. 1st year orientation program will be commence on
                      November 10, 2022
                    </b>
                    <ol>
                      <li>Office – 0141-2715071</li>
                      <li>Mr. Satyanarayan Sharma - 9549651643</li>
                      <li>
                        Dr. Vinita Tiwari – 9549654678 (For Institute Academics)
                      </li>
                      <li>
                        Dr. Basant Agarwal – 9549650345 (For Institute
                        Academics)
                      </li>
                    </ol>
                  </h6>
                  <br />
                  <h5>
                    <b>For Placement related queries, Please contact</b>
                  </h5>
                  <h6>
                    <ol>
                      <li>Office – 0141-2715071 </li>
                      <li>Dr. Amit Kumar Garg - 9549650432</li>
                      <li>
                        Dr. Vinita Tiwari – 9549654678 (For Institute Academics)
                      </li>
                      <li>
                        Dr. Basant Agarwal – 9549650345 (For Institute
                        Academics)
                      </li>
                    </ol>
                  </h6>
                  <br />

                  <h5>
                    <b>For queries, Please contact</b>
                  </h5>
                  <h6>
                    <ol>
                      <li>
                      Office of IIIT Kota - 0141-2715071 (For General Query)
                      </li>
                      <li>
                      Dr. Amit Kumar – 9549655278 (Admission InCharge)
                      </li>
                      <li>
                      Dr. Basant Agarwal – 9549650345 (For Institute Academics)
                      </li>
                      <li>
                      Dr. Vinita Tiwari – 9549654678 (For Institute Academics)
                      </li>
                      <li>
                      Dr. Ashish Sharma – 9549650767 (For Training and Placement)
                      </li>
                    </ol>
                  </h6>
                  <br />
                  <h6>
                    <b>Note:</b> Working hours 9:00 AM to 5:00 PM
                  </h6>
                </Col>
              </Row>
              <Row className="justify-content-center text-center mt-5 mb-5">
                <Col lg="12">
                  <h4>
                    <b>
                      Keep checking the JoSAA/CSAB and IIIT Kota websites
                      regularly for getting latest updates for UG admission
                      2022-23.
                    </b>
                  </h4>
                </Col>

                <Col className="mt-4" lg={4}>
                  <Button
                    className="btn-1 ml-1 theme-color-kota-bg"
                    type="button"
                    href="https://josaa.nic.in/"
                  >
                    JOSSA
                  </Button>
                </Col>
                <Col className="mt-4" lg={4}>
                  <Button
                    className="btn-1 ml-1 theme-color-kota-bg"
                    type="button"
                    href="https://csab.nic.in/"
                  >
                    CSAB
                  </Button>
                </Col>
              </Row> */}
            </Container>
          </section>
        </main>
        <NewFooter />
      </>
    );
  }
}

export default UgProcedure;
